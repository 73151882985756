import React from "react"
import { graphql } from "gatsby"

import { Link as GatsbyLink } from "gatsby"
import {
  Link,
  Trans,
  useTranslation,
} from "gatsby-plugin-react-i18next"
import Layout from "../components/layout"
import SEO from "../components/seo"
import scrollTo from "gatsby-plugin-smoothscroll"
import loadable from "@loadable/component"

const Player = loadable(() => import("react-lazy-youtube"))

const IndexPage = ({ data }) => {
  const { t, i18n } = useTranslation()

  return (
    <Layout>
      <SEO title={t("INDEX.TITLE")} description={t("INDEX.DESCRIPTION")} />
      <div className="main-header image is-3by1">
        <div className="is-flex has-ratio">
          <div className="column-mobile-image">
            <div className="is-hidden-desktop column-mobile-image-cover has-text-centered">
              <div className="columns is-mobile is-vcentered is-marginless">
                <div className="column">
                  <div className="platform-baloon">
                    <span className="has-text-white is-size-5">
                      <Trans>INDEX.FOR_OWNERS</Trans>
                    </span>
                  </div>
                  {(i18n.language === "en-gb" && (
                    <div className="platform-text">
                      <span>A</span>
                      <br />
                      <span>simple</span>
                      <br />
                      <span className="has-text-white">takeaway</span>
                      <br />
                      <span>solution</span>
                    </div>
                  )) ||
                    (i18n.language === "ja-gb" && (
                      <div className="platform-text">
                        <span className="has-text-white">お持ち帰り</span>
                        <br />
                        <span>始めませんか？</span>
                      </div>
                    )) ||
                    (i18n.language === "ja-jp" && (
                      <div className="platform-text">
                        <span className="has-text-white">お持ち帰り</span>
                        <br />
                        <span>始めませんか？</span>
                      </div>
                    ))}
                  <button
                    className="button is-yellow is-medium"
                    onClick={() => scrollTo("#ready-to-start-takeaway")}
                  >
                    <Trans>Start today</Trans>
                  </button>
                  {(i18n.language === "ja-gb" && (
                    <Link className="button is-small" to="/" language="ja-jp">
                      日本に店舗をお持ちの方はこちら
                    </Link>
                  )) ||
                    (i18n.language === "ja-jp" && (
                      <Link
                        className="button is-small"
                        to="/"
                        language="ja-gb"
                      >
                        イギリスに店舗をお持ちの方はこちら
                      </Link>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="is-hidden-touch column-simple-takeaway-platform">
            <div>
              {(i18n.language === "en-gb" && (
                <div className="platform-text">
                  <span>A</span>
                  <br />
                  <span>simple</span>
                  <br />
                  <span className="has-text-primary">takeaway</span>
                  <br />
                  <span>solution</span>
                </div>
              )) ||
                (i18n.language === "ja-gb" && (
                  <div className="platform-text">
                    <span></span>
                    <br />
                    <span className="has-text-primary">お持ち帰り</span>
                    <br />
                    <span>始めませんか？</span>
                    <br />
                    <span></span>
                    <br />
                  </div>
                )) ||
                (i18n.language === "ja-jp" && (
                  <div className="platform-text">
                    <span></span>
                    <br />
                    <span className="has-text-primary">お持ち帰り</span>
                    <br />
                    <span>始めませんか？</span>
                    <br />
                    <span></span>
                    <br />
                  </div>
                ))}
              <button
                className="button is-yellow is-medium"
                onClick={() => scrollTo("#ready-to-start-takeaway")}
              >
                <Trans>Start today</Trans>
              </button>
              {(i18n.language === "ja-gb" && (
                <Link className="button is-medium" to="/" language="ja-jp">
                  日本に店舗をお持ちの方はこちら
                </Link>
              )) ||
                (i18n.language === "ja-jp" && (
                  <Link className="button is-medium" to="/" language="ja-gb">
                    イギリスに店舗をお持ちの方はこちら
                  </Link>
                ))}
            </div>
          </div>
          <div className="is-hidden-touch column-laptop-image"></div>
        </div>
      </div>

      <section className="section is-medium" id="why-uketoly">
        <div className="container has-text-centered">

          <div className="column is-4-desktop is-offset-4-desktop">
            <img src="/images/1st-anniv.svg" alt="1st Anniversary" />
          </div>

          {(i18n.language === "en-gb" && (
            <h1 className="title">
              <img src="/images/whyuketoly.svg" alt="whyuketoly" />
            </h1>
          )) ||
            (i18n.language === "ja-gb" && (
              <h1 className="title">
                <img
                  src="/images/goodpoint_ja.svg"
                  alt="ウケトリーのいいところ"
                />
              </h1>
            )) ||
            (i18n.language === "ja-jp" && (
              <h1 className="title">
                <img
                  src="/images/goodpoint_ja.svg"
                  alt="ウケトリーのいいところ"
                />
              </h1>
            ))}
          {(i18n.language === "en-gb" && (
            <div className="columns is-desktop">
              <div className="column">
                <figure className="rounded-icon rounded-icon-pizzabox"></figure>
                <h2>Focus on Takeaways</h2>
                <p>
                  Uketoly is built for Takeaways.
                  <br />
                  Your orders won't be rushed, you can schedule pick-up times.
                </p>
              </div>
              <div className="column">
                <figure className="rounded-icon rounded-icon-smallpaperbag"></figure>
                <h2>Simple</h2>
                <p>
                  There are only a few setting to get started.
                  <br />
                  Uketoly is simple and easy to use.
                </p>
              </div>
              <div className="column">
                <figure className="rounded-icon rounded-icon-box"></figure>
                <h2>Affordable Fee</h2>
                <p>
                  No hidden fee. View <Link to="/pricing">pricing</Link>.<br />
                  Pay-as-you-go pricing 6.4% + 20p *
                  {/* <small>(7.9% + 20p for non-European cards)</small> */}
                </p>
              </div>
            </div>
          )) ||
            (i18n.language === "ja-gb" && (
              <div className="columns is-desktop is-multiline">
                <div className="column is-4-desktop">
                  <figure className="rounded-icon rounded-icon-box"></figure>
                  <h2>良心的な利用料</h2>
                  <p>
                    利用料は使った分だけ。
                    <br />
                    決済成立ごとに 6.4% + 20p ※
                    {/* <small>(7.9% + 20p for non-European cards)</small> */}
                  </p>
                </div>
                <div className="column is-4-desktop">
                  <figure className="rounded-icon rounded-icon-pizzabox"></figure>
                  <h2>自分のペースで営業</h2>
                  <p>
                    受取時間と締切時間を柔軟に設定できるため、自分のペースで注文を受けられます。
                  </p>
                </div>
                <div className="column is-4-desktop">
                  <figure className="rounded-icon rounded-icon-smallpaperbag"></figure>
                  <h2>簡単な操作</h2>
                  <p>
                    初期設定も日々の操作も簡単。
                    <br />
                    誰でも使えます。
                  </p>
                </div>
                <div className="column">
                  <figure className="rounded-icon rounded-icon-pot"></figure>
                  <h2>日本語でのサポート</h2>
                  <p>
                    お困りのことがあれば、日本語でサポートいたします。
                    <br />
                    お気軽にお問い合わせください。
                  </p>
                </div>
                <div className="column">
                  <figure className="rounded-icon rounded-icon-cup"></figure>
                  <h2>シンプルな注文ページ</h2>
                  <p>
                    アプリのダウンロードや会員登録といった手間をお客様に与えません。
                    <br />
                    簡単に注文ができるデザインです。
                  </p>
                </div>
              </div>
            )) ||
            (i18n.language === "ja-jp" && (
              <div className="columns is-desktop is-multiline">
                <div className="column is-4-desktop">
                  <figure className="rounded-icon rounded-icon-box"></figure>
                  <h2>良心的な利用料</h2>
                  <p>
                    利用料は使った分だけ。
                    <br />
                    決済成立ごとに 8.6% (決済・振込手数料込み)
                  </p>
                </div>
                <div className="column is-4-desktop">
                  <figure className="rounded-icon rounded-icon-pizzabox"></figure>
                  <h2>自分のペースで営業</h2>
                  <p>
                    受取時間と締切時間を柔軟に設定できるため、自分のペースで注文を受けられます。
                  </p>
                </div>
                <div className="column is-4-desktop">
                  <figure className="rounded-icon rounded-icon-smallpaperbag"></figure>
                  <h2>簡単な操作</h2>
                  <p>
                    初期設定も日々の操作も簡単。
                    <br />
                    誰でも使えます。
                  </p>
                </div>
                <div className="column">
                  <figure className="rounded-icon rounded-icon-cup"></figure>
                  <h2>シンプルな注文ページ</h2>
                  <p>
                    アプリのダウンロードや会員登録といった手間をお客様に与えません。
                    <br />
                    簡単に注文ができるデザインです。
                  </p>
                </div>
              </div>
            ))}
          <div className="columns is-desktop">
            <div className="column is-6-desktop is-offset-3-desktop">
              <figure className="image is-16by9 youtube">
                <Player
                  id="bn92144K1-o"
                  imageSize="hqdefault"
                  className="has-ratio"
                />
              </figure>
            </div>
          </div>
          {(i18n.language === "en-gb" && (
            <p>We provide you an online order storefront.</p>
          )) ||
            (i18n.language === "ja-gb" && (
              <p>こんな注文ページが簡単に作れます。</p>
            )) ||
            (i18n.language === "ja-jp" && (
              <p>こんな注文ページが簡単に作れます。</p>
            ))}

          <div
            className="columns is-desktop is-centered"
            style={{ marginTop: "16px" }}
          >
            <div className="column is-6-desktop">
              <Link
                className="button is-yellow is-large is-fullwidth"
                to="contact"
              >
                <Trans>Request a demo</Trans>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div class="custom-shape-divider">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
      <section className="section is-medium" id="how-to-get-started">
        <div className="container-fluid has-text-centered">
          <h1 className="title">
            <Trans>How to get started</Trans>{" "}
            <img
              src="/images/questions.svg"
              className="img-questions"
              alt="question"
            />
          </h1>
          <div className="columns is-multiline is-mobile">
            <div className="column is-12 has-text-left">
              <img src={t(`INDEX.STEP1_SVG`)} alt="step1" />
            </div>
            <div className="column is-12 has-text-right">
              <img src={t(`INDEX.STEP2_SVG`)} alt="step2" />
            </div>
            <div className="column is-12 has-text-left">
              <img src={t(`INDEX.STEP3_SVG`)} alt="step3" />
            </div>
          </div>
        </div>
      </section>
      <section className="section is-medium" id="ready-to-start-takeaway">
        <div className="container has-text-centered">
          <h1 className="title">
            <Trans>Ready to start</Trans>{" "}
            <img
              src="/images/questions.svg"
              className="img-questions"
              alt="question"
            />
          </h1>
          <div className="content">
            <div className="columns is-desktop is-centered">
              <div className="column is-6-desktop has-text-left">
                {(i18n.language === "en-gb" && (
                  <p>
                    <span role="img" aria-label="v">
                      ✔️
                    </span>{" "}
                    <strong>Free hosted</strong> ordering storefront website
                    <br />
                    <span role="img" aria-label="v">
                      ✔️
                    </span>{" "}
                    <strong>Mobile friendly</strong> order page
                    <br />
                    <span role="img" aria-label="v">
                      ✔️
                    </span>{" "}
                    Close orders up to <strong>72 hours</strong> before pick-up
                    or specific time
                    <br />
                    <span role="img" aria-label="v">
                      ✔️
                    </span>{" "}
                    Payments are processed by{" "}
                    <a
                      href="https://stripe.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Stripe (payment gateway)
                    </a>
                    <br />
                    <span role="img" aria-label="v">
                      ✔️
                    </span>{" "}
                    Simple <Link to="/pricing">pricing</Link>,{" "}
                    <strong>No</strong> cancellation fee !<br />
                    <span role="img" aria-label="v">
                      ✔️
                    </span>{" "}
                    PAYG 5% Uketoly fee + Stripe fee per transaction
                    <br />
                  </p>
                )) ||
                  (i18n.language === "ja-gb" && (
                    <p>
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      <strong>スマホ対応</strong>の注文ページ
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      <a
                        href="https://stripe.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Stripe
                      </a>{" "}
                      による<strong>安全</strong>なオンライン決済
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      <strong>日本語</strong>によるサポート
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      <strong>リーズナブル</strong>でわかりやすい利用料
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      受取時間の締め切りを<strong>柔軟</strong>に設定可能
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      <strong>自由</strong>にメニューを登録・変更可能
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      ゆっくりお料理が作れます
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      材料のムダを減らせます
                    </p>
                  )) ||
                  (i18n.language === "ja-jp" && (
                    <p>
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      <strong>スマホ対応</strong>の注文ページ
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      <a
                        href="https://stripe.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Stripe
                      </a>{" "}
                      による<strong>安全</strong>なオンライン決済
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      <strong>日本語</strong>によるサポート
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      <strong>リーズナブル</strong>でわかりやすい利用料
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      受取時間の締め切りを<strong>柔軟</strong>に設定可能
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      <strong>自由</strong>にメニューを登録・変更可能
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      ゆっくりお料理が作れます
                      <br />
                      <span role="img" aria-label="v">
                        ✔️
                      </span>{" "}
                      材料のムダを減らせます
                    </p>
                  ))}
              </div>
            </div>
            <div className="columns is-desktop is-centered">
              {(i18n.language === "en-gb" && (
                <div className="column is-6-desktop">
                  <a
                    className="button is-yellow is-large is-fullwidth"
                    href="https://docs.google.com/forms/d/e/1FAIpQLScwEvFeQ_0a3xPUN4X2x45rEV32O-QXKyPVAt391baJBrT5Eg/viewform?usp=sf_link"
                  >
                    {t(`start_free_trial`)}
                  </a>
                  <p>
                    <small>
                      After your free trial there is a setup fee. If you do not
                      continue, there is no setup fee.{" "}
                      <GatsbyLink to="legal/trial-terms">
                        Terms and conditions apply
                      </GatsbyLink>
                    </small>
                  </p>
                  <p style={{ marginTop: "16px" }}>
                    Still have any questions ? <Link to="faq">FAQ</Link>
                  </p>
                  <p style={{ marginTop: "16px" }}>
                    Feel free to <Link to="contact">contact us</Link>
                  </p>
                </div>
              )) ||
                ((i18n.language === "ja-gb" || i18n.language === "ja-jp") && (
                  <div className="column is-6-desktop">
                    <a
                      className="button is-yellow is-large is-fullwidth"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSdFHE2R_5ztVKU4NkKSlTToPpQjf5bkKpdYQlZONrqKPhVMQg/viewform?usp=sf_link"
                    >
                      {t(`start_free_trial`)}
                    </a>
                    <p>
                      <small>
                        初期費用なしで30日間ご利用いただけます。30日後、継続しない場合は初期費用がかかりません。いつでも無料でキャンセルができます。
                        <GatsbyLink to="legal/trial-terms">利用規約</GatsbyLink>
                      </small>
                    </p>
                    <p style={{ marginTop: "16px" }}>
                      <Link to="faq">もっと知りたい？</Link>
                    </p>
                    <p style={{ marginTop: "16px" }}>
                      <Link to="contact">お気軽にお問い合わせ下さい</Link>
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
